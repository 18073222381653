import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import VideoView from "../../../components/trainingVideo2"
import PropTypes from "prop-types"

const videoTutorials = [
  {
    id: "1",
    mainTitle: "Intro",
    subContent: [
      {
        id: "1.1",
        sno: "tut1",
        image: "/images/player.svg",
        title: "Intro to Fuse Classroom",
        videoUrl: "https://youtu.be/165RGGWHjGs?si=LiU9X8_oEy02dH5U",
        videoId: "165RGGWHjGs",
        popOver: "",
      },
    ],
  },
  {
    id: "2",
    mainTitle: "Classroom",
    subContent: [
      {
        id: "2.1",
        sno: "tut2",
        image: "/images/player.svg",
        title: "Login using OTP",
        videoUrl: "https://youtu.be/htZpqvE8gGA?si=UHV8Co1PjUtTjptX",
        videoId: "htZpqvE8gGA",
        popOver: "",
      },
      {
        id: "2.2",
        sno: "tut3",
        image: "/images/player.svg",
        title: "Join Live Classes",
        videoUrl: "https://youtu.be/-LA6XNb-zJM?si=FK8JxzrdHaRaiGvx",
        videoId: "-LA6XNb-zJM",
        popOver: "",
      },
      {
        id: "2.3",
        sno: "tut4",
        image: "/images/player.svg",
        title: "Recorded Live Classes",
        videoUrl: "https://youtu.be/IAyA_7B3dq4?si=suhc4CmnmU-ud8mC",
        videoId: "IAyA_7B3dq4",
        popOver: "",
      },
      {
        id: "2.4",
        sno: "tut5",
        image: "/images/player.svg",
        title: "Assignments and Grades",
        videoUrl: "https://youtu.be/-XfqiR5cH64?si=EfTRpXYblEmRhbau",
        videoId: "-XfqiR5cH64",
        popOver: "",
      },
    ],
  },
]

class Instructor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggleOn: false,
      selectedVideoTutorial: videoTutorials[0].subContent[0],
      allVideoList: [],
    }
  }

  componentDidMount() {
    this.activeMenuLink()
    this.findById()
  }

  activeMenuLink = () => {
    let content = []
    videoTutorials.map(video => {
      if (video.subContent.length > 0) {
        content = content.concat(video.subContent)
      }
    })

    let queryString = this.props.location.search
    let urlParams = new URLSearchParams(queryString)
    let value = urlParams.get("videoRequested")

    if (this.props.location.search) {
      this.setState({ videoRequested: value })
    }

    let object = content.filter(content => content.sno === value)
    if (object.length) {
      this.setState({
        selectedVideoTutorial: object[0],
      })
    } else {
      this.setState({
        selectedVideoTutorial: videoTutorials[0].subContent[0],
      })
    }
  }

  componentWillMount() {
    let content = []
    videoTutorials.map(video => {
      if (video.subContent.length > 0) {
        content = content.concat(video.subContent)
      }
    })
    this.setState({ allVideoList: content })
  }

  handleToggle = () => {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
    }))
  }

  findById = (idx = this.state.selectedVideoTutorial.id) => {
    let a
    videoTutorials.forEach(subList => {
      subList.subContent.map(subList => {
        let Inner = { ...subList }

        if (idx === Inner.id) {
          a = Inner
          return a
        }
      })
    })
    return a
  }

  changeVideo = video => {
    this.setState({ selectedVideoTutorial: video })
  }

  changeNextVideo = () => {
    let index = this.state.allVideoList.findIndex(x => x === this.state.selectedVideoTutorial)
    if (index < this.state.allVideoList.length - 1) {
      this.setState({ selectedVideoTutorial: this.state.allVideoList[index + 1] })
    } else {
      this.setState({ selectedVideoTutorial: this.state.allVideoList[0] })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title={"Admin"} titleDesc={"-"} titleKeywords={"-"} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

        {/* top section */}
        <div className="training">
          {/* sidebar */}
          <aside className={`training-sidebar ${this.state.isToggleOn ? "sidebar-show" : ""}`}>
            <Link to="/" className="navbar-brand">
              <svg xmlns="http://www.w3.org/2000/svg" width="230.378" height="30.772" viewBox="0 0 230.378 30.772">
                <g id="Group_11777" data-name="Group 11777" transform="translate(1704.678 -7651)">
                  <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="1.891"
                    height="30.772"
                    transform="translate(-1628.361 7651)"
                    fill="#ffffff"
                  />
                  <g id="Group_36" data-name="Group 36" transform="translate(-1617.568 7651.71)">
                    <path
                      id="Path_213"
                      data-name="Path 213"
                      d="M3350.613,4199.808a7.939,7.939,0,0,1,5.888,2.332l-.854,1.119a7.148,7.148,0,0,0-5.034-2.1,7.273,7.273,0,0,0,.01,14.538,7.259,7.259,0,0,0,5.491-2.372l.724,1.15a8.383,8.383,0,0,1-6.215,2.57,8.268,8.268,0,0,1-8.49-8.58A8.36,8.36,0,0,1,3350.613,4199.808Z"
                      transform="translate(-3342.133 -4193.618)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_214"
                      data-name="Path 214"
                      d="M3372.051,4189.766h1.381l.012,19.274c0,2.3,1.186,2.564,1.943,2.564a3.77,3.77,0,0,0,.493-.034v1.282a3.028,3.028,0,0,1-.591.068c-.954,0-3.223-.36-3.226-3.453Z"
                      transform="translate(-3353.61 -4189.765)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_215"
                      data-name="Path 215"
                      d="M3391.546,4206.592h1.086v-.56c0-3.65-1.878-4.9-4.509-4.9a7.731,7.731,0,0,0-4.7,1.582l-.79-1.119a8.608,8.608,0,0,1,5.556-1.812c3.783,0,5.824,2.1,5.826,6.213l.007,10.623h-1.381v-2.171a7.5,7.5,0,0,1,.13-1.48h-.066a6.323,6.323,0,0,1-5.753,4.05c-2.664,0-5.593-1.477-5.594-4.766C3381.35,4206.663,3388.619,4206.594,3391.546,4206.592Zm-4.533,9.146c3.584,0,5.622-3.721,5.62-6.977v-.887h-1.053c-2.8,0-8.781.006-8.779,4.281C3382.8,4213.9,3384.217,4215.74,3387.013,4215.738Z"
                      transform="translate(-3357.179 -4193.608)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_216"
                      data-name="Path 216"
                      d="M3408.442,4213.585a7.165,7.165,0,0,0,5.1,2.069c2.006,0,3.781-1.054,3.78-3.094,0-4.176-9.047-2.987-9.049-8.349,0-2.927,2.463-4.44,5.259-4.443a6.486,6.486,0,0,1,4.868,1.774l-.822,1.118a5.622,5.622,0,0,0-4.144-1.543c-1.875,0-3.717.858-3.715,3,0,4.275,9.047,2.987,9.05,8.446,0,2.631-2.233,4.442-5.259,4.444a7.956,7.956,0,0,1-5.955-2.365Z"
                      transform="translate(-3367.23 -4193.603)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_217"
                      data-name="Path 217"
                      d="M3430.639,4213.573a7.162,7.162,0,0,0,5.1,2.067c2.006,0,3.781-1.055,3.781-3.094,0-4.177-9.046-2.987-9.05-8.348,0-2.928,2.464-4.442,5.259-4.444a6.487,6.487,0,0,1,4.869,1.773l-.821,1.118a5.628,5.628,0,0,0-4.145-1.543c-1.875,0-3.715.857-3.715,3,0,4.276,9.046,2.988,9.05,8.447,0,2.631-2.233,4.442-5.259,4.443a7.953,7.953,0,0,1-5.955-2.364Z"
                      transform="translate(-3375.746 -4193.598)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_218"
                      data-name="Path 218"
                      d="M3454.227,4200.3h1.382v3.125a6.9,6.9,0,0,1-.13,1.348h.065c.82-2.533,2.661-4.6,5.259-4.607a5.041,5.041,0,0,1,.954.1v1.382a5.328,5.328,0,0,0-.756-.065c-2.566,0-4.176,2.173-4.9,4.607a11.681,11.681,0,0,0-.491,3.455l0,7.1-1.382,0Z"
                      transform="translate(-3385.135 -4193.755)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_219"
                      data-name="Path 219"
                      d="M3477.1,4199.727a8.459,8.459,0,0,1,8.524,8.514,8.521,8.521,0,1,1-17.037.01A8.458,8.458,0,0,1,3477.1,4199.727Zm.01,15.887a7.151,7.151,0,0,0,7.066-7.372,7.072,7.072,0,1,0-14.143.008A7.151,7.151,0,0,0,3477.113,4215.613Z"
                      transform="translate(-3390.645 -4193.587)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_220"
                      data-name="Path 220"
                      d="M3509.117,4199.707a8.457,8.457,0,0,1,8.524,8.513,8.521,8.521,0,1,1-17.037.012A8.457,8.457,0,0,1,3509.117,4199.707Zm.01,15.886a7.149,7.149,0,0,0,7.065-7.371,7.072,7.072,0,1,0-14.142.009A7.151,7.151,0,0,0,3509.127,4215.593Z"
                      transform="translate(-3402.926 -4193.579)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_221"
                      data-name="Path 221"
                      d="M3534.585,4200.082h1.381l0,2.893a6.924,6.924,0,0,1-.131,1.35h.066a7.343,7.343,0,0,1,6.313-4.642c3.289,0,4.835,1.542,5.2,4.47h.067a7.154,7.154,0,0,1,6.246-4.477c4.243,0,5.494,2.5,5.5,6.41l.007,10.426h-1.382l-.006-9.933c0-3.092-.627-5.625-4.114-5.622-2.729,0-5.063,2.437-5.786,5a8.631,8.631,0,0,0-.327,2.534l0,8.025h-1.381l-.006-9.933c0-2.829-.4-5.625-4.016-5.622-2.927,0-5.229,2.569-5.983,5.3a8.167,8.167,0,0,0-.261,2.237l0,8.024-1.382,0Z"
                      transform="translate(-3415.962 -4193.566)"
                      fill="#ffffff"
                    />
                  </g>
                  <g id="Group_37" data-name="Group 37" transform="translate(-1704.678 7651.148)">
                    <path
                      id="Path_222"
                      data-name="Path 222"
                      d="M3202.884,4200.007h-2.073l0-4.441h2.073v-.4c0-5.557,5.029-6.317,7.759-6.319a13.292,13.292,0,0,1,1.71.1l0,4.8a6.3,6.3,0,0,0-.921-.065c-1.119,0-2.862.2-2.861,1.646v.229h3.125l0,4.44h-3.124l.008,12.5-5.69,0Z"
                      transform="translate(-3200.808 -4188.852)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_223"
                      data-name="Path 223"
                      d="M3223.275,4205.22a.659.659,0,0,0-.724-.724h-1.25l0-4.77,5.263,0c1.677,0,2.4.723,2.4,2.4l0,7.531c0,1.579.627,2.236,1.811,2.235,2.434,0,3.583-2.14,3.582-4.673l0-7.5,5.69,0,.007,11.446a.658.658,0,0,0,.724.723h1.25l0,4.77-5.032,0c-1.545,0-2.4-.754-2.4-1.9v-.23a3.416,3.416,0,0,1,.033-.4h-.066a5.815,5.815,0,0,1-5.26,2.93c-3.355,0-6.02-1.575-6.022-6.344Z"
                      transform="translate(-3208.668 -4193.02)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_224"
                      data-name="Path 224"
                      d="M3259.913,4210.052s2.37,2.433,4.968,2.432c.822,0,1.282-.232,1.282-.824,0-1.415-8.488-1.869-8.491-7.363,0-3.651,3.384-5.231,6.969-5.234,2.763,0,6.579.786,6.581,3.844v2.007l-4.934,0v-.823c0-.461-.724-.788-1.546-.787-.789,0-1.513.263-1.512.79,0,1.743,8.585,1.507,8.589,7.2,0,3.42-2.99,5.494-6.936,5.5a10.258,10.258,0,0,1-7.5-3.088Z"
                      transform="translate(-3222.511 -4192.769)"
                      fill="#ffffff"
                    />
                    <path
                      id="Path_225"
                      data-name="Path 225"
                      d="M3292.917,4199.046c5,0,7.83,3.481,7.833,8.184,0,.56-.13,1.71-.13,1.71l-10.689.007a3.778,3.778,0,0,0,3.949,3.089,7.9,7.9,0,0,0,4.5-1.779l2.272,3.813a10.914,10.914,0,0,1-7.234,2.7c-6.052,0-9.311-4.37-9.313-8.874C3284.106,4202.933,3287.458,4199.048,3292.917,4199.046Zm2.011,6.445a2.134,2.134,0,0,0-2.008-2.168,2.716,2.716,0,0,0-2.827,2.172Z"
                      transform="translate(-3232.763 -4192.763)"
                      fill="#ffffff"
                    />
                  </g>
                </g>
              </svg>
            </Link>

            <div className="training-title">Video Tutorials</div>

            <div className="training-menu">
              {/* loop */}
              {videoTutorials.map(list => (
                <div key={list.id}>
                  <div className="menu-item">{list.mainTitle}</div>

                  {list.subContent.map(subList => (
                    <div
                      className={
                        this.state.selectedVideoTutorial && subList.id === this.state.selectedVideoTutorial.id
                          ? "menu-link active"
                          : "menu-link"
                      }
                      key={subList.id}
                      onClick={() => {
                        this.changeVideo(subList)
                        this.findById()
                      }}
                    >
                      <img src={subList.image} className="mr-2" /> {subList.title}
                    </div>
                  ))}
                </div>
              ))}
              <Link to="/training/student" className="menu-item d-block">
                More Student Tutorials
              </Link>
            </div>
          </aside>
          <VideoView
            handleToggle={this.handleToggle}
            isToggleOn={this.state.isToggleOn}
            isSelectedVideo={videoTutorials}
            isSelectedVideoid={this.state.selectedVideoTutorial.id}
            changeNextVideo={this.changeNextVideo}
            content={this.findById}
          />
        </div>
      </Layout>
    )
  }
}

Instructor.propTypes = {
  location: PropTypes.object,
}

export default Instructor
