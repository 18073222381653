import React from "react"
import { Row, Col } from "react-bootstrap"
import VideoPlayer from "./youTube-Video"

class VideoView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
    }
  }

  render() {
    let ids = this.props.content()
    return (
      <section className={`training-content active ${this.props.isToggleOn ? "content-slide" : ""}`}>
        <article className={`container-fluid active`}>
          <Row className="video-title align-items-center">
            <Col xs={8} className="">
              <div className="sidebar-toggle" onClick={this.props.handleToggle}>
                <span />
                <span />
                <span />
              </div>

              <div className="white-space" title={ids.title}>
                {ids.title}
              </div>
            </Col>

            <Col xs={4} className="text-right">
              <button className="btn btn-success" onClick={this.props.changeNextVideo}>
                Next Video
              </button>
            </Col>
          </Row>

          {/* video player */}
          <Row className="justify-content-center">
            <Col md={10} className="py-3">
              <div className="youtube-player">
                <VideoPlayer videoId={ids.videoId} />
              </div>
            </Col>
          </Row>
        </article>
      </section>
    )
  }
}

export default VideoView
